import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static values = {
    animation: Number,
    handle: String
  }

  static targets = [ "value" ]

  initialize() {
    super.initialize()
    this.onUpdate = this.onUpdate.bind(this)
  }

  connect() {
    this.sortable = new Sortable(this.element, {
      ...this.defaultOptions,
      ...this.options
    })
  }

  disconnect() {
    this.sortable.destroy()
    this.sortable = undefined
  }

  async onUpdate(e) {
    let count = 1
    this.valueTargets.forEach( (el) => {
      el.value = count
      count += 1
    })
  }

  valueTargetConnected(element) {
    this.onUpdate()
  }

  get options() {
    return {
      animation: this.animationValue || this.defaultOptions.animation || 150,
      handle: this.handleValue || this.defaultOptions.handle || undefined,
      onUpdate: this.onUpdate,
      onAdd: this.onUpdate,
    }
  }

  get defaultOptions() {
    return {}
  }
}
