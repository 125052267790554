
import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs"
import { patch } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "list" ]
  static values = {
    resourceName: String,
    paramName: {
      type: String,
      default: "position"
    },
    responseKind: {
      type: String,
      default: "html"
    },
    animation: Number,
    handle: String
  }

  initialize() {
    this.onUpdate = this.onUpdate.bind(this)
    this.onAdd = this.onAdd.bind(this)
  }

  connect() {
    this.sortables = this.listTargets.map( (el) => {
      return new Sortable(el, {
        ...this.options,
        group: el.dataset.sortableTreeListGroup || "default",
      })
    })
  }

  disconnect() {
    this.sortables.forEach( (el) => el.destroy() )
    this.sortables = undefined
  }

  async onAdd(e) {
    this.onUpdate(e)
  }

  async onUpdate({to, item, newIndex}) {
    if (!item.dataset.sortableTreeUpdateUrl) return

    const param = this.resourceNameValue
      ? `${this.resourceNameValue}[${this.paramNameValue}]`
      : this.paramNameValue

    const parentId = to.dataset.sortableTreeId

    const data = new FormData()
    data.append(param, newIndex + 1)
    data.append(`${this.resourceNameValue}[parent_id]`, parentId || "")

    return await patch(item.dataset.sortableTreeUpdateUrl, {
      body: data,
      responseKind: this.responseKindValue
    })
  }

  get options() {
    return {
      animation: this.animationValue || 150,
      handle: this.handleValue || undefined,
      onUpdate: this.onUpdate,
      onAdd: this.onAdd,
      fallbackOnBody: true,
      swapThreshold: 0.65,
    }
  }
}
