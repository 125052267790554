import { StreamActions } from "@hotwired/turbo"
import {Utils} from "turbo_power";
import { Modal } from "bootstrap"

const PERMITTED_ATTRIBUTES = [

]

function modal() {
  // console.log(this.attributes)

  const attributes = Array.from(this.attributes)
    .map((attribute) => [Utils.camelize(attribute.name), Utils.typecast(attribute.value)])

  const options = Object.fromEntries(attributes)

  this.targetElements.forEach((element) => {
    var bsModal = Modal.getOrCreateInstance(element, options)
    bsModal[options.method]()
  })
}

StreamActions.modal = modal
